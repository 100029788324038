import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader} from "../../../_metronic/_partials/controls";
import {FormattedMessage} from "react-intl";
import DeleteIcon from "@material-ui/icons/Delete";
import {deleteBanner, getAllBanner} from "../../../http/configurationCRUD";
import {makeStyles} from "@material-ui/core/styles";
import AddBanner from "./AddBanner";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(2)
    },
    bannerWrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
    },
    bannerItem: {
        borderRadius: "8px",
        position: "relative",
        height: 0,
        paddingTop: "56%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
    },
}));


export default function BannerPage() {
    const classes = useStyles();
    const [banners, setBanners] = useState([]);
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [selectedId, setSelectedId] = useState(0);

    console.log(banners)
    useEffect(() => {
        getAllBanner().then((res) => {
            console.log(res.data.result);
            setBanners(res.data.result);
        })
    }, []);

    const addBanner = (banner) => {
        const temp = [...banners];
        temp.push(banner);
        console.log(temp)
        setBanners(temp);
    }

    const deleteAction = () => {
        console.log(selectedId)
        deleteBanner({bannerId: selectedId}).then((res) => {
            const temp = banners.filter(x => x.id !== selectedId);
            setBanners(temp);
            setShowDeletePopup(false);
        }).catch(err => {
            console.log(err);
        })
    }

    const toolbar = (
        <div className="card-toolbar">
            <button
                className="btn btn-primary pt-3 my-2 w-125px"
                onClick={() => setShowAddPopup(true)}
            >
                추가
            </button>
        </div>
    );


    return (
        <>
            {showAddPopup && <AddBanner setShowAddPopup={setShowAddPopup} addBanner={addBanner}/>}
            {showDeletePopup && (
                <div className={"popup-container"}>
                    <div className={"popup-body warning"}>
                        <div className="popup-text font-size-h4 p-3">
                            배너삭제
                        </div>
                        <div className={"popup-text font-size-h2 text-center py-10"}>
                            <FormattedMessage id={"GENERAL.DELETE_REALLY"}/>
                        </div>
                        <div className={"d-flex justify-content-between pt-4"}>
                            <button className={"btn btn-primary font-size-h5 w-120px"} onClick={deleteAction}>
                                <FormattedMessage id={"GENERAL.YES"}/>
                            </button>
                            <button className={"btn btn-danger font-size-h5 w-120px"} onClick={() => setShowDeletePopup(false)}>
                                <FormattedMessage id={"GENERAL.CANCEL"}/>
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Card className="min-h-300px">
                <CardHeader title={"배너관리"} toolbar={toolbar}/>
                <CardBody>
                    <div className={classes.bannerWrapper}>
                        {banners.map((item, idx) => {
                            return (
                                <div key={`${idx}`} className={"p-2"}>
                                    <div
                                         className={classes.bannerItem}
                                         style={{backgroundImage: `url(${item.image})`}}
                                    >
                                        <div className="position-absolute bottom-0 left-0 text-white">
                                            <div className="px-3 py-2 font-size-h5">{item.title}</div>
                                            <div className="px-3 py-2">{item.subtitle}</div>
                                        </div>
                                        <div className="position-absolute top-0 right-0 p-3" onClick={() => {
                                            setSelectedId(item.id);
                                            setShowDeletePopup(true);
                                        }}>
                                            <DeleteIcon className="text-light-secondary"/>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </CardBody>
            </Card>
        </>
    )
}
