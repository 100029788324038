/* eslint-disable no-restricted-imports */
import React, {useEffect, useState} from "react";
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
} from "@material-ui/core";
import {Card, CardHeader, CardBody} from "../../../_metronic/_partials/controls";
import {makeStyles} from "@material-ui/core/styles";
import {updateUserPoint} from '../../../http/userCRUD';
import Spinner from "../../components/Spinner";
import {getProfileByUserId, getUserPointHistory} from "../../../http/userCRUD";


const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(2)
    },
    table: {
        minWidth: 1200
    },
    tableWrapper: {
        overflowX: "auto"
    },
}));

export default function UsersPage(props) {
    const {userId} = props;
    const classes = useStyles();

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const [rows, setRows] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [point, setPoint] = useState(0);
    const [originPoint, setOriginPoint] = useState(0);
    const [editable, setEditable] = useState(false);



    useEffect(() => {
        setLoading(true)
        getProfileByUserId({userId})
            .then(res => {
                setLoading(false)
                console.log(userId, res.data.result)
                setPoint(res.data.result?.pointAmount || 0);
                setOriginPoint(res.data.result?.pointAmount || 0);
            })
            .catch(err => {
                setLoading(false)
                console.log(err);
            })

        getUserPointHistory({userId, limit: 500}).then(res => {
            const hist = res.data.result?.map(x => ({
                id: x.id,
                content: x.content,
                point: x.point,
                originPoint: x.originPoint,
                createdDate: new Date(x.createdDate).toLocaleString(),
            }));
            setRows(hist);
            setTotalCount(res.data.totalCount);
        }).catch(err => {
            console.log(err);
        })

    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
    }

    const updatePoint = () => {
        if (point !== originPoint) {
            updateUserPoint({
                userId: userId,
                pointAmount: point,
                originPoint: originPoint,
            }).then(res => {
                const temp = res.data.result;
                setRows([
                    {
                        id: temp.id,
                        content: temp.content,
                        point: temp.point,
                        originPoint: temp.originPoint,
                        createdDate: new Date(temp.createdDate).toLocaleString(),
                    },
                    ...rows,
                ])
                setEditable(false);
            }).catch(err => {
                console.log(err);
            })
        }
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <>
            <Card>
                <CardHeader title={`보유포인트`}/>
                <CardBody>
                    <div className='p-2 d-flex align-items-center'>
                        <div>보유포인트량</div>
                        <input
                            type="text"
                            className='form-control w-125px ml-2'
                            value={point}
                            onChange={event => setPoint(event.target.value)}
                            onBlur={updatePoint}
                            disabled={!editable}
                        />
                        <button
                            className='btn btn-primary ml-2'
                            onClick={() => setEditable(true)}
                        >
                            수정
                        </button>
                    </div>
                    <div className={classes.root}>
                        <Paper>
                            <div className={classes.tableWrapper}>
                                {loading ? <Spinner/> : (
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow className="bg-primary-o-50">
                                                <TableCell>아이디</TableCell>
                                                <TableCell>내용</TableCell>
                                                <TableCell>포인트</TableCell>
                                                <TableCell>날자</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            ).map((row, idx) => {

                                                return (
                                                    <TableRow key={`${idx}`}>
                                                        <TableCell>{row.id}</TableCell>
                                                        <TableCell>{row.content}</TableCell>
                                                        <TableCell>
                                                            {row.originPoint
                                                                ? `${row.point} (이전 포인트량 ${row.originPoint})`
                                                                : row.point
                                                            }
                                                        </TableCell>
                                                        <TableCell>{row.createdDate}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 78 * emptyRows}}>
                                                    <TableCell colSpan={10}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                )}
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={totalCount || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    "aria-label": "Previous Page"
                                }}
                                nextIconButtonProps={{
                                    "aria-label": "Next Page"
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}
