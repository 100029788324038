import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import StyledDropzone from "../../components/StyledDropzone";
import {registerBanner} from "../../../http/configurationCRUD";

export default function AddBanner(props) {
    const {setShowAddPopup, addBanner} = props;
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [image, setImage] = useState('');
    const [bannerLink, setBannerLink] = useState('');

    const registerAction = () => {
        registerBanner({
            title: title,
            subtitle: subTitle,
            link: bannerLink,
            image: image,
        }).then((res) => {
            addBanner(res.data.data);
            setShowAddPopup(false);
        }).catch(err => {
            console.log(err);
        })
    };

    const setImageUploadUrl = (url) => {
        setImage(url);
    };

    return (
        <div className={"popup-container"}>
            <div className={"popup-body"}>
                <div className="d-flex justify-content-center">
                    <div className="w-325px">
                        <StyledDropzone func={setImageUploadUrl}/>
                    </div>
                </div>

                <div className="p-3">
                    <TextField
                        id="outlined-search"
                        label={"제목"}
                        className={"d-flex"}
                        margin="none"
                        variant="outlined"
                        value={title}
                        onChange={event => setTitle(event.target.value)}
                    />
                </div>

                <div className="p-3">
                    <TextField
                        id="outlined-search"
                        label={"간단한 토픽"}
                        className={"d-flex"}
                        margin="none"
                        variant="outlined"
                        value={subTitle}
                        onChange={event => setSubTitle(event.target.value)}
                    />
                </div>

                <div className="p-3">
                    <TextField
                        id="outlined-search"
                        label={"링크"}
                        className={"d-flex"}
                        margin="none"
                        variant="outlined"
                        value={bannerLink}
                        onChange={event => setBannerLink(event.target.value)}
                    />
                </div>

                <div className="p-3 d-flex justify-content-between">
                    <button className="btn btn-primary w-120px" onClick={registerAction}>보관</button>
                    <button className="btn btn-danger w-120px" onClick={() => {
                        setShowAddPopup(false);
                    }}>
                        취소
                    </button>
                </div>
            </div>
        </div>
    )
}
