/* global kakao */

import React, { useEffect, useState, useRef } from "react";

export default function KakaoMap(props) {
    const { markerPositions, size } = props;
    const [kakaoMap, setKakaoMap] = useState(null);
    const [, setMarkers] = useState([]);

    const container = useRef();

    // useEffect(() => {
    //     window.__WEBVIEW_BRIDGE__ = {
    //         init: function() {
    //             try {
    //                 document.addEventListener('message', e => alert(e.data));
    //             } catch (err) {
    //                 console.log(err);
    //             }
    //         }
    //     }
    //
    //     window.__WEBVIEW_BRIDGE__.init();
    // }, [])

    useEffect(() => {
        const script = document.createElement("script");
        script.src =
            "https://dapi.kakao.com/v2/maps/sdk.js?appkey=79baab7d2cf3c429bd084c20049c880a&autoload=false";
        document.head.appendChild(script);

        script.onload = () => {
            kakao.maps.load(() => {
                const center = new kakao.maps.LatLng(37.50802, 127.062835);
                const options = {
                    center,
                    level: 3
                };
                const map = new kakao.maps.Map(container.current, options);
                //setMapCenter(center);
                setKakaoMap(map);
            });
        };
    }, [container]);

    useEffect(() => {
        if (kakaoMap === null) {
            return;
        }

        // save center position
        const center = kakaoMap.getCenter();

        // change viewport size
        // const [width, height] = size;
        container.current.style.width = `100vw`;
        container.current.style.height = `100vh`;

        // relayout and...
        kakaoMap.relayout();
        // restore
        kakaoMap.setCenter(center);
    }, [kakaoMap, size]);

    useEffect(() => {
        if (kakaoMap === null) {
            return;
        }

        const positions = markerPositions.map(pos => new kakao.maps.LatLng(...pos));

        setMarkers(markers => {
            // clear prev markers
            markers.forEach(marker => marker.setMap(null));

            // assign new markers
            return positions.map(
                position => new kakao.maps.Marker({ map: kakaoMap, position })
            );
        });

        if (positions.length > 0) {
            const bounds = positions.reduce(
                (bounds, latlng) => bounds.extend(latlng),
                new kakao.maps.LatLngBounds()
            );

            kakaoMap.setBounds(bounds);
        }
    }, [kakaoMap, markerPositions]);

    return <div id="container" ref={container} />;
}
