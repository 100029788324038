/* eslint-disable no-restricted-imports */
import React, {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {Hidden, InputAdornment, InputLabel, MenuItem, Select} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {Card, CardBody, CardHeader} from "../../../_metronic/_partials/controls";
import {
    Grid,
    TextField,
} from "@material-ui/core/";
import StyledDropzone from "../../components/StyledDropzone";
import Spinner from "../../components/Spinner";
import CancelIcon from '@material-ui/icons/Cancel';
import {getPostById, registerPost, updatePost} from "../../../http/postCRUD";
import jwt_decode from "jwt-decode";
import {useSelector, useDispatch} from "react-redux";
import {actions} from '../../../redux/commonRedux';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        textAlign: "center",
        flexBasis: '20%',
        flexShrink: 0,
        color: '#0008',
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: "600",
        color: theme.palette.text.secondary,
    },
    nested: {
        paddingLeft: theme.spacing(5),
    },
    settingBg: {
        backgroundColor: '#bdbdbd',
        borderRadius: 3,
    },
    imageItem: {
        borderRadius: "8px",
        position: "relative",
        height: 0,
        paddingTop: "56%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
    },
}));

let imageList = [];
export default function PostEditPage(props) {

    const classes = useStyles();
    const postId = props.location.pathname.split("/").pop();
    const token = localStorage.getItem("jwtToken");
    const user = token ? jwt_decode(token) : undefined;

    const [images, setImages] = useState([]);
    const [link, setLink] = useState('');
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (postId !== 'edit') {
            setLoading(true);
            getPostById({postId}).then((res) => {
                const post = res.data.result;
                const temp = post.postImages.map(x => (x.image));
                setContent(post.content);
                setImages(temp);
                setLink(post.link);
                imageList = [...temp];
                setLoading(false);
            }).catch(err => {
                console.log(err);
                setLoading(false);
            });
        }

        return () => {
            imageList = [];
        }
    }, []);


    const registerAction = () => {
        const data = {
            postId,
            userId: user?.id,
            link,
            content,
            images,
        };

        if (content) {
            if (postId === 'edit') {
                registerPost(data)
                    .then((res) => {
                        imageList = [];
                        setImages([]);
                        props.history.push('/post');
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                updatePost(data)
                    .then((res) => {
                        imageList = [];
                        setImages([]);
                        props.history.push("/post");
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        } else {
            alert("게시물내용을 입력하세요")
        }
    }

    const setImageUploadUrl = (url) => {
        console.log(url)
        console.log(imageList.length)
        imageList.push(url);
        setImages([...imageList]);
    }

    const cancelAction = (idx) => {
        console.log(imageList)
        console.log(idx)
        imageList.splice(idx, 1);
        setImages([...imageList]);
    }

    const toolbar = (
        <div className="card-toolbar">
            <button className={"btn btn-primary mr-4 w-120px"} onClick={registerAction}>
                <FormattedMessage id={"GENERAL.SAVE"}/>
            </button>
            <button
                className="btn btn-danger w-120px"
                onClick={() => props.history.push('/post')}
            >
                <FormattedMessage id={"GENERAL.CANCEL"}/>
            </button>
        </div>
    );

    return (
        <>
            <Card>
                <CardHeader
                    title={postId === 'edit' ? "게시물추가" : "게시물편집"}
                    toolbar={toolbar}
                />
                {loading ? <Spinner/> : (
                    <CardBody>
                        <div className="multi-image-upload">
                            {images.map((item, idx) => {
                                return (
                                    <div key={`${idx}`} className={classes.imageItem} style={{backgroundImage: `url(${item})`}}>
                                        <div
                                            className='text-right position-absolute top-0 right-0 p-2'
                                            onClick={() => cancelAction(idx)}
                                        >
                                            <CancelIcon className='text-danger'/>
                                        </div>
                                    </div>
                                )
                            })}
                            <div>
                                <StyledDropzone func={setImageUploadUrl} isMulti={true}/>
                            </div>
                        </div>
                        <Grid item sm={12} className={'pt-5'}>
                            <Grid item sm={12}>
                                <TextField
                                    className={"d-flex mb-5"}
                                    label={"URL"}
                                    variant="outlined"
                                    value={link}
                                    onChange={event => setLink(event.target.value)}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <TextField
                                    className={"d-flex mb-5"}
                                    label={"내용"}
                                    multiline
                                    rows={8}
                                    rowsMax={8}
                                    variant="outlined"
                                    value={content}
                                    onChange={event => setContent(event.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </CardBody>
                )}
            </Card>
        </>
    );
}
