/* eslint-disable no-restricted-imports */
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    InputAdornment,
} from "@material-ui/core";
import {Card, CardHeader, CardBody} from "../../../_metronic/_partials/controls";
import {FormattedMessage} from "react-intl";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import SearchIcon from '@material-ui/icons/Search';
import {useSelector, useDispatch} from "react-redux";
import Spinner from "../../components/Spinner";
import {CSVLink} from "react-csv";
import {
    getCompetitionOverview
} from "../../../http/competitionCRUD";


const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(2)
    },
    table: {
        minWidth: 1200
    },
    tableWrapper: {
        overflowX: "auto"
    },
    popupImageBox: {
        borderRadius: '4px',
        width: '50%',
        overflow: "hidden",
    }
}));

export default function CompetitionOverview(props) {
    const competitionId = props.location.pathname.split("/").pop();
    console.log(competitionId)
    const classes = useStyles();

    const LIMIT = 25;
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const [rows, setRows] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [rankingData, setRankingData] = useState([]);

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getOverviewAction({competitionId});
    }, []);

    const getOverviewAction = (data) => {
        getCompetitionOverview(data).then(res => {
            console.log(res.data.result?.rows);

            const temp = res.data.result?.rows?.map(x => ({
                id: x.id,
                userId: x.user?.id,
                name: x.user?.name,
                email: x.user?.email,
                createdDate: new Date(x.user?.createdDate).toLocaleString(),
            }));

            setRows([...rows, ...temp]);
            setRankingData([...rows, ...temp]);
            setTotalCount(res.data.result?.count);
            setLoading(false);
        }).catch(err => {
            console.log(err);
        })
    }

    const searchAction = (e) => {
        let searchKey = e.target.value.toUpperCase();
        let result = [];
        rankingData.map(item => {
            if (item?.userId?.toString()?.toUpperCase()?.indexOf(searchKey) > -1 ||
                item?.name?.toUpperCase()?.indexOf(searchKey) > -1 ||
                item?.email?.toUpperCase()?.indexOf(searchKey) > -1 ||
                item?.createdDate?.toUpperCase()?.indexOf(searchKey) > -1)
            {
                result.push(item);
            }
        })
        setRows(result);
    }

    const handleChangePage = (event, newPage) => {

        setPage(newPage);

        if (newPage * rowsPerPage === rows.length) {
            getOverviewAction({
                competitionId: competitionId,
                limit: LIMIT,
                offset: rows.length,
            });
        }
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
    }

    const toolbar = (
        <div className="card-toolbar">
            <button
                className="btn btn-success mr-2"
                onClick={() => props.history.goBack()}
            >
                {'대회목록 가기'}
            </button>
            <button
                type="button"
                className="btn btn-primary pt-3 my-2"
            >
                <CSVLink data={rows} filename='attending_overview.csv' style={{color: '#fff'}}>
					<span className="svg-icon svg-icon-md svg-icon-white">
						<SVG src={toAbsoluteUrl(
                            "/media/svg/icons/Files/Selected-file.svg"
                        )} className="h-50 align-self-center"/>
					</span>
                    <FormattedMessage id={"GENERAL.EXPORT_EXCEL"}/>
                </CSVLink>
            </button>
        </div>
    );

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <>
            <Card className="min-h-300px">
                <CardHeader title={'참가현황'} toolbar={toolbar}/>
                <CardBody>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                            <TextField
                                id="outlined-search"
                                label={<FormattedMessage id={"GENERAL.PLACEHOLDER_SEARCH"}/>}
                                type="search"
                                className={"d-flex"}
                                margin="none"
                                variant="outlined"
                                onKeyUp={searchAction}
                                InputProps={{
                                    endAdornment: <InputAdornment position={"end"}><SearchIcon/></InputAdornment>
                                }}
                            />
                        </Grid>
                    </Grid>

                    <div className={classes.root}>
                        <Paper>
                            <div className={classes.tableWrapper}>
                                {loading ? <Spinner/> : (
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow className="bg-primary-o-50">
                                                <TableCell>순위</TableCell>
                                                <TableCell>아이디</TableCell>
                                                <TableCell>이름</TableCell>
                                                <TableCell>이메일</TableCell>
                                                <TableCell>가입날짜</TableCell>
                                                <TableCell align={"right"}>액션</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            ).map((row, idx) => {
                                                // const editPath = '/competition/edit/' + row.id;

                                                return (
                                                    <TableRow key={`${idx}`}>
                                                        <TableCell>{idx + 1}</TableCell>
                                                        <TableCell>{row.userId}</TableCell>
                                                        <TableCell>{row.name}</TableCell>
                                                        <TableCell>{row.email}</TableCell>
                                                        <TableCell>{row.createdDate}</TableCell>
                                                        <TableCell align={"right"}>

                                                            <Link to={'/competition/fish-list'}>
                                                                <div className="btn btn-primary mr-2">
                                                                    {'보기'}
                                                                </div>
                                                            </Link>

                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 78 * emptyRows}}>
                                                    <TableCell colSpan={10}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                )}
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={totalCount || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    "aria-label": "Previous Page"
                                }}
                                nextIconButtonProps={{
                                    "aria-label": "Next Page"
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}
