import React, {useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import Grid from "@material-ui/core/Grid";
import {deletePostComment, getPostById} from "../../../http/postCRUD";
import ImageGallery from 'react-image-gallery';
import {Link} from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import {Card, CardHeader, CardBody} from "../../../_metronic/_partials/controls";


export default function PostDetailPage(props) {
    const postId = props.location.pathname.split("/").pop();

    const [post, setPost] = useState({});
    const [images, setImages] = useState([]);
    const [commentId, setCommentId] = useState(0);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getPostById({postId}).then((res) => {
            console.log(res.data.result)
            const data = res.data.result;
            setPost(data);
            let temp = res.data.result.postImages?.map(x => ({
                original: x.image,
            }));
            setImages(temp);
            const sortTemp = data.postComments;
            sortTemp.sort((a, b) => {
                if (a.createdDate > b.createdDate) return 1;
                if (a.createdDate < b.createdDate) return -1;
                return 0;
            });
            setComments(sortTemp);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            console.log(err);
        })
    }, []);

    const deleteAction = () => {
        deletePostComment({postCommentId: commentId}).then((res) => {
            const temp = {...post};
            const temp1 = temp.postComments.filter(x => x.id !== commentId);
            temp.postComments = [...temp1];
            setPost(temp);
            setShowDeletePopup(false);
        }).catch(err => {
            console.log(err);
        })
    }

    const toolbar = (
        <div className="card-toolbar">
            <Link to={'/post'}>
                <div className="btn btn-primary w-120px">목록가기</div>
            </Link>
        </div>
    );


    return (
        <>
            {showDeletePopup && (
                <div className={"popup-container"}>
                    <div className={"popup-body warning"}>
                        <div className="popup-text font-size-h4 p-3">
                            댓글삭제
                        </div>
                        <div className={"popup-text font-size-h2 text-center py-10"}>
                            <FormattedMessage id={"GENERAL.DELETE_REALLY"}/>
                        </div>
                        <div className={"d-flex justify-content-between pt-4"}>
                            <button className={"btn btn-primary font-size-h5 w-120px"} onClick={deleteAction}>
                                <FormattedMessage id={"GENERAL.YES"}/>
                            </button>
                            <button className={"btn btn-danger font-size-h5 w-120px"}
                                    onClick={() => setShowDeletePopup(false)}>
                                <FormattedMessage id={"GENERAL.CANCEL"}/>
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Card className="min-h-300px">
                <CardHeader title={"게시물상세정보"} toolbar={toolbar}/>
                <CardBody>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                            <Grid item xs={12}>
                                <div className="bg-light-warning rounded mb-2 w-50">
                                    <div className="p-3">게시날짜: <span>{post.createdDate}</span></div>
                                    <div className="p-3">게시물의 유저아이디: <span>{post.user?.id}</span></div>
                                    <div className={"p-3"}>유저이름: <span>{post.user?.name}</span></div>
                                </div>
                                <div>
                                    <ImageGallery items={images}/>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <div className="p-3">게시물내용:</div>
                            <div className="p-3">{post.content}</div>
                            <div className={'p-3'}>링크:</div>
                            <div className={'p-3'}>
                                <a href={post.link} target={"_blank"}>{post.link}</a>
                            </div>
                            <div className={"p-3"}>댓글목록:</div>
                            {comments?.map((item, idx) => {
                                return (
                                    <div key={`${idx}`}
                                         className="position-relative border rounded bg-light-primary mb-2 hover-opacity-70 w-auto">
                                        <div className="p-1 font-size-xs text-dark-50">
                                            날짜: <span>{new Date(item.createdDate).toLocaleString()}</span>
                                        </div>
                                        <div className="p-1 font-size-xs text-dark-50">유저아이디: <span>{item.userId}</span>
                                        </div>
                                        <div
                                            className="p-1 font-size-xs text-dark-50">유저이름: <span>{item.user?.name}</span>
                                        </div>
                                        <div className="p-1">
                                            {item.comment}
                                        </div>
                                        <div className="position-absolute" style={{top: '10px', right: '10px'}}
                                             onClick={() => {
                                                 setCommentId(item.id);
                                                 setShowDeletePopup(true);
                                             }}
                                        >
                                            <DeleteIcon className="text-dark-20"/>
                                        </div>
                                    </div>
                                )
                            })}
                        </Grid>
                    </Grid>
                </CardBody>
            </Card>
        </>
    )
}
