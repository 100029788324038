/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import CanvasJSReact from '../../../lib/canvasjs.react';
import {FormattedMessage} from "react-intl";
import {getStyleStatistic} from "../../../http/dashboardCRUD";
import Spinner from "../Spinner";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export function GraphicalWidget(props) {
    const {className} = props;
    const [optionsStyle, setOptionsStyle] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getStyleStatistic().then(res => {
            const statistic = res.data.result;
            console.log(statistic);
            setOptionsStyle({
                theme: "light2",
                animationEnabled: true,
                title: {
                    text: "칭호",
                    fontSize: 16,
                },
                height: 400,
                data: [{
                    type: "pie",
                    startAngle: 0,
                    toolTipContent: "<b>{label}</b>: {y}명",
                    showInLegend: false,
                    legendText: "{label}",
                    indexLabelFontSize: 14,
                    indexLabel: "{label} {y}명",
                    indexLabelFontColor: "#000",
                    indexLabelPlacement: "outside",
                    dataPoints: [
                        {y: statistic[0], label: "초보 낚맛꾼"},
                        {y: statistic[1], label: "수습 낚맛꾼"},
                        {y: statistic[2], label: "숙련된 낚맛꾼"},
                        {y: statistic[3], label: "전문 낚맛꾼"},
                        {y: statistic[4], label: "낚시광"},
                        {y: statistic[5], label: "내가 왕이 될 상인가?"},
                        {y: statistic[6], label: "낚시명인"},
                        {y: statistic[7], label: "낚시의 맛을 아는자!"},
                    ]
                }]
            })
            setLoading(false);
        }).catch(err => {
            console.log(err)
            setLoading(false);
        })
    }, [])

    return (
        <>
            {/* begin::Tiles Widget 1 */}
            <div className={`card card-custom ${className}`}>
                {/* begin::Header */}
                <div className="card-header border-0 pt-5">
                    <div className="card-title">
                        <div className="card-label">
                            <div className="font-weight-bolder">
                                <FormattedMessage id={"DASHBOARD.GRAPHICAL_STATISTIC"}/>
                            </div>
                            {/*<div className="font-size-sm text-muted mt-2">890,344 Sales</div>*/}
                        </div>
                    </div>
                    <div className="card-toolbar">
                    </div>
                </div>
                {/* end::Header */}

                {/* begin::Body */}
                <div className="card-body d-flex flex-column px-0 py-0">
                    <div className={"row"} style={{flex: 'unset'}}>
                        {loading ? <Spinner/> : (
                            <div className={"col xs-12 sm-6"}>
                                <CanvasJSChart options={optionsStyle}/>
                            </div>
                        )}
                    </div>
                    {/*<CanvasJSChart options={options_bar_chart}/>*/}
                </div>
                {/* end::Body */}
            </div>
            {/* end::Tiles Widget 1 */}
        </>
    );
}
