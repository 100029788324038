/* eslint-disable no-restricted-imports */
import React, {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {
    Hidden,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table, TableBody, TableCell,
    TableHead, TablePagination,
    TableRow
} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {Card, CardBody, CardHeader} from "../../../_metronic/_partials/controls";
import {
    Grid,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    TextField,
    Switch
} from "@material-ui/core/";
import StyledDropzone from "../../components/StyledDropzone";
import {
    getCompetitionById,
    getCompetitionOverview,
    registerCompetition,
    updateCompetition
} from "../../../http/competitionCRUD";
import {getDateByISOFormat, getDateTimeByISOFormat} from "../../../utils/computations";
import {getAllFishTypes} from "../../../http/configurationCRUD";
import Spinner from "../../components/Spinner";
import CompetitionTabs from "./CompetitionTabs";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from "@material-ui/lab/AlertTitle";
import SearchIcon from '@material-ui/icons/Search';
import * as common from "../../../redux/commonRedux";
import {useDispatch} from "react-redux";
import {CSVLink} from "react-csv";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        textAlign: "center",
        flexBasis: '20%',
        flexShrink: 0,
        color: '#0008',
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: "600",
        color: theme.palette.text.secondary,
    },
    nested: {
        paddingLeft: theme.spacing(5),
    },
    settingBg: {
        backgroundColor: '#bdbdbd',
        borderRadius: 3,
    },
    imageItem: {
        borderRadius: "8px",
        position: "relative",
        height: 0,
        paddingTop: "56%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
    },
}));

const START_DAY = 4;

let imageList = [];
export default function CompetitionEditPage(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const competitionId = props.location.pathname.split("/").pop();

    const [competitionType, setCompetitionType] = useState('NORMAL');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    const [start, setStart] = useState(new Date(0));
    const [end, setEnd] = useState(new Date(0));
    const [attendNumber, setAttendNumber] = useState(0);
    const [attendStart, setAttendStart] = useState(new Date(0));
    const [attendEnd, setAttendEnd] = useState(new Date(0));
    const [isDuplicated, setIsDuplicated] = useState(false);
    const [mode, setMode] = useState('');
    const [attendCost, setAttendCost] = useState(0);
    const [totalReward, setTotalReward] = useState(0);
    const [fishType, setFishType] = useState(0);
    const [rankFishNumber, setRankFishNumber] = useState(0);
    const [questFishNumber, setQuestFishNumber] = useState(0);
    const [questFishWidth, setQuestFishWidth] = useState(0);
    const [questSpecialWidth, setQuestSpecialWidth] = useState(0);
    const [reward1, setReward1] = useState(0);
    const [reward2, setReward2] = useState(0);
    const [reward3, setReward3] = useState(0);
    const [terms, setTerms] = useState('');
    const [fishTypeList, setFishTypeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [toastMsg, setToastMsg] = useState(false);
    const [toastErrMsg, setToastErrMsg] = useState(false);

    const LIMIT = 25;
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const [rows, setRows] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [rankingData, setRankingData] = useState([]);

    let tmr = -1;
    let tmr1 = -1;

    useEffect(() => {
        dispatch(common.actions.setCompetitionTabValue(1));

        if (competitionId !== 'edit') {
            getOverviewAction({competitionId});
        }

        getAllFishTypes()
            .then((res) => {
                setFishTypeList(res.data.result);
            })
            .catch(err => {
                console.log(err);
            });

        setNextStartAndEndDate();

        if (competitionId !== 'edit') {
            setLoading(true);
            getCompetitionById({competitionId}).then((res) => {
                const competition = res.data.result;
                // console.log(typeof JSON.parse(competition.images))
                setCompetitionType(competition.type === 1 ? "NORMAL" : "SPECIAL");
                setName(competition.name);
                setDescription(competition.description);
                setImages(JSON.parse(competition.images || []));
                setStart(getDateTimeByISOFormat(competition.startDate));
                setEnd(getDateTimeByISOFormat(competition.endDate));
                setAttendNumber(competition.maxAttendNumber);
                setAttendStart(getDateTimeByISOFormat(competition.startApplication));
                setAttendEnd(getDateTimeByISOFormat(competition.endApplication));
                setIsDuplicated(competition.duplicateAllow);
                setMode(competition.mode);
                setAttendCost(competition.attendCost);
                setTotalReward(competition.totalReward);
                setFishType(competition.fishTypeId);
                setRankFishNumber(competition.rankFishNumber);
                setQuestFishNumber(competition.questFishNumber);
                setQuestFishWidth(competition.questFishWidth);
                setQuestSpecialWidth(competition.questSpecialWidth);
                setReward1(competition.reward1);
                setReward2(competition.reward2);
                setReward3(competition.reward3);
                setTerms(competition.termsAndConditions);
                setLoading(false);
            }).catch(err => {
                console.log(err);
                setLoading(false);
            });
        }

        return () => {
            imageList = [];
        }
    }, []);

    const initialize = () => {
        setNextStartAndEndDate();

        setCompetitionType('NORMAL');
        setName('');
        setDescription('');
        setImages([])
        setStart('');
        setEnd('');
        setAttendNumber('');
        setAttendStart('');
        setAttendEnd('');
        setIsDuplicated(false);
        setMode('');
        setAttendCost('');
        setTotalReward('');
        setFishType('');
        setRankFishNumber('');
        setQuestFishNumber('');
        setQuestFishWidth('');
        setQuestSpecialWidth('');
        setReward1('');
        setReward2('');
        setReward3('');
        setTerms('');
        imageList = [];
    }

    const registerAction = () => {
        const data = {
            competitionId,
            type: competitionType === "NORMAL" ? 1 : 2,
            name: name,
            description: description,
            images: JSON.stringify(images),
            startDate: start && new Date(start).toISOString(),
            endDate: end && new Date(end).toISOString(),
            maxAttendNumber: attendNumber,
            startApplication: attendStart && new Date(attendStart).toISOString(),
            endApplication: attendEnd && new Date(attendEnd).toISOString(),
            duplicateAllow: isDuplicated,
            totalReward: totalReward,
            fishTypeId: fishType,
            mode: mode,
            rankFishNumber: rankFishNumber,
            questFishNumber: questFishNumber,
            questFishWidth: questFishWidth,
            questSpecialWidth: questSpecialWidth,
            reward1: reward1,
            reward2: reward2,
            reward3: reward3,
            attendCost: attendCost,
            termsAndConditions: terms,
        };
        console.log(data)
        if ((['NORMAL', 'SPECIAL'].includes(competitionType)) && name !== '' && start !== '' && end !== '' && mode !== '') {
            if (competitionId === 'edit') {
                registerCompetition(data)
                    .then((res) => {
                        setToastMsg(true);

                        tmr = setTimeout(function () {
                            setToastMsg(false);
                        }, 5000);

                        initialize();
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.response?.data?.msg === 'INVALID_DATE') {
                            alert('대회날짜가 유효하지 않습니다.');
                        }
                    });
            } else {
                updateCompetition(data)
                    .then((res) => {
                        imageList = [];
                        props.history.push("/competition/list");
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.response?.data?.msg === 'INVALID_DATE') {
                            alert('종료날짜가 유효하지 않습니다.');
                        }
                    });
            }
        } else {
            setToastErrMsg(true);
            tmr1 = setTimeout(function () {
                setToastErrMsg(false);
            }, 5000);
        }
    }

    const setFileUploadUrl = (url) => {
        console.log(url)
        setTerms(url);
    }

    const setNextStartAndEndDate = () => {
        const now = new Date();
        let diff = START_DAY - now.getDay();
        if (diff < 1) diff += 7;
        const startDate = new Date(now.getTime() + diff * 86400000); // a day = 86400000 milliseconds
        const endDate = new Date(startDate.getTime() + 6 * 86400000);

        setStart(getDateByISOFormat(startDate) + 'T00:00:00');
        setEnd(getDateByISOFormat(endDate) + 'T23:59:59');
    }

    const handleChangeType = (e) => {
        const type = e.target.value;
        setCompetitionType(type);

        // set the default value of the start time and the end time
        if (type === "NORMAL") {
            setNextStartAndEndDate();
        } else {
            setStart('');
            setEnd('');
        }
    }

    const getOverviewAction = (data) => {
        getCompetitionOverview(data).then(res => {
            console.log(res.data.result?.rows);

            const temp = res.data.result?.rows?.map(x => ({
                id: x.id,
                userId: x.userId,
                name: x.user?.name,
                email: x.user?.email,
                createdDate: new Date(x.user?.createdDate).toLocaleString(),
            }));

            setRows([...rows, ...temp]);
            setRankingData([...rows, ...temp]);
            setTotalCount(res.data.result?.count);
            setLoading(false);
        }).catch(err => {
            console.log(err);
        })
    }

    const searchAction = (e) => {
        let searchKey = e.target.value.toUpperCase();
        let result = [];
        rankingData.map(item => {
            if (item?.userId?.toString()?.toUpperCase()?.indexOf(searchKey) > -1 ||
                item?.name?.toUpperCase()?.indexOf(searchKey) > -1 ||
                item?.email?.toUpperCase()?.indexOf(searchKey) > -1 ||
                item?.createdDate?.toUpperCase()?.indexOf(searchKey) > -1)
            {
                result.push(item);
            }
        })
        setRows(result);
    }

    const setImageUploadUrl = (url) => {
        console.log('*********** imageList: ', imageList.length)
        if (imageList.length < 3) {
            imageList.push(url);
            setImages([...imageList]);
        } else {
            alert('최대 3개의 이미지가 업로드 가능합니다.');
        }
    }

    const cancelAction = (idx) => {
        console.log(imageList)
        imageList.splice(idx, 1);
        setImages([...imageList]);
    }

    const handleChangePage = (event, newPage) => {

        setPage(newPage);

        if (newPage * rowsPerPage === rows.length) {
            getOverviewAction({
                competitionId: competitionId,
                limit: LIMIT,
                offset: rows.length,
            });
        }
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
    }

    const toolbar = (
        <div className="card-toolbar">
            <button className={"btn btn-primary mr-4 w-120px"} onClick={registerAction}>
                <FormattedMessage id={"GENERAL.SAVE"}/>
            </button>
            <button
                className="btn btn-danger w-120px"
                onClick={() => competitionId === 'edit' ? initialize() : props.history.push('/competition/list')}
            >
                <FormattedMessage id={"GENERAL.CANCEL"}/>
            </button>
        </div>
    );

    const attendingToolbar = (
        <div className="card-toolbar">
            <button
                type="button"
                className="btn btn-primary pt-3 my-2"
            >
                <CSVLink data={rows} filename='attending_overview_list.csv' style={{color: '#fff'}}>
					<span className="svg-icon svg-icon-md svg-icon-white">
						<SVG src={toAbsoluteUrl(
                            "/media/svg/icons/Files/Selected-file.svg"
                        )} className="h-50 align-self-center"/>
					</span>
                    <FormattedMessage id={"GENERAL.EXPORT_EXCEL"}/>
                </CSVLink>
            </button>
        </div>
    );

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    console.log('imageList: ', imageList.length)
    console.log('images: ', images.length)

    return (
        <>
            {toastMsg ? (
                <div className={'toast-msg-box'}>
                    <Alert severity="success" className={'toast-msg'}>
                        <AlertTitle>Success</AlertTitle>
                        <strong>Registered New Competition Successfully!</strong>
                    </Alert>
                </div>
            ) : null}
            {toastErrMsg ? (
                <div className={'toast-msg-box'}>
                    <Alert severity="error" className={'toast-msg'}>
                        <AlertTitle>Error</AlertTitle>
                        Some fields are empty — <strong>check it out!</strong>
                    </Alert>
                </div>
            ) : null}

            <Card>
                <CompetitionTabs match={props.match} history={props.history}/>
                <CardHeader
                    title={<FormattedMessage id={competitionId === 'edit'
                        ? "COMPETITION.COMPETITION_ADD"
                        : "COMPETITION.COMPETITION_EDIT"}/>}
                    toolbar={toolbar}
                />
                {loading ? <Spinner/> : (
                    <CardBody>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="CompetitionType"
                                            name="competitionType"
                                            className={classes.radioGroup}
                                            value={competitionType}
                                            onChange={handleChangeType}
                                            row
                                >
                                    <FormControlLabel value={"NORMAL"} control={<Radio/>} label="상시대회"/>
                                    <FormControlLabel value={"SPECIAL"} control={<Radio/>} label="비정기대회"/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={6}>
                                <Grid item xs={12}>
                                    <TextField
                                        className={"d-flex mb-5"}
                                        label={"대회이름"}
                                        variant="outlined"
                                        value={name}
                                        onChange={event => setName(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        className={"d-flex mb-5"}
                                        label={"대회설명"}
                                        multiline
                                        rows={4}
                                        rowsMax={4}
                                        variant="outlined"
                                        value={description}
                                        onChange={event => setDescription(event.target.value)}
                                    />
                                </Grid>
                                <Grid container spacing={2} className={"mb-3"}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            className={"d-flex"}
                                            label={"시작날짜"}
                                            type={"datetime-local"}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={start}
                                            onChange={event => setStart(event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            className={"d-flex"}
                                            label={"종료날짜"}
                                            type={"datetime-local"}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={end}
                                            onChange={event => setEnd(event.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} className={"mb-3"}>
                                    <Grid item xs={6} sm={4}>
                                        <TextField
                                            className={"d-flex mb-4"}
                                            label={"참가인원"}
                                            variant="outlined"
                                            type={"number"}
                                            value={attendNumber}
                                            onChange={event => setAttendNumber(event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <TextField
                                            className={"d-flex mb-4"}
                                            label={"참여포인트"}
                                            variant="outlined"
                                            type={"number"}
                                            value={attendCost}
                                            onChange={event => setAttendCost(event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} className={"mt-2"}>
                                        <FormControlLabel
                                            className={"mb-0"}
                                            control={<Switch
                                                name={"allowDuplicate"}
                                                color="primary"
                                                checked={isDuplicated}
                                                onChange={() => setIsDuplicated(!isDuplicated)}
                                            />}
                                            label={"중복참여가능"}
                                        />
                                    </Grid>
                                </Grid>
                                {competitionType === "SPECIAL" && (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                className={"d-flex"}
                                                label={"접수시작날짜"}
                                                type={"datetime-local"}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={attendStart}
                                                onChange={event => setAttendStart(event.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                className={"d-flex"}
                                                label={"접수마감날짜"}
                                                type={"datetime-local"}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={attendEnd}
                                                onChange={event => setAttendEnd(event.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Grid container spacing={2} className={"mb-5"}>
                                    <Grid item xs={12} sm={6} md={7}>
                                        <FormControl variant="outlined" className="d-flex mr-2">
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                대회방식
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={mode}
                                                onChange={event => setMode(event.target.value)}
                                                label={"대회방식"}
                                            >
                                                <MenuItem value={1}>랭킹전</MenuItem>
                                                <MenuItem value={2}>퀘스트전(길이조건)</MenuItem>
                                                <MenuItem value={3}>퀘스트전(마리조건)</MenuItem>
                                                <MenuItem value={4}>퀘스트전(길이+마리조건)</MenuItem>
                                                <MenuItem value={5}>퀘스트전(특정길이조건)</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={5}>
                                        <FormControl variant="outlined" className="d-flex mr-2">
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                대상어종
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={fishType}
                                                onChange={event => setFishType(event.target.value)}
                                                label={"대상어종"}
                                            >
                                                <MenuItem value={0}>전체</MenuItem>
                                                {fishTypeList.map((item, idx) => {
                                                    return (
                                                        <MenuItem key={`${idx}`} value={item.id}>{item.name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {mode === 1 && (
                                    <Grid item xs={12}>
                                        <TextField
                                            className={"d-flex mb-4"}
                                            label={"합계마리수"}
                                            variant="outlined"
                                            type={"number"}
                                            value={rankFishNumber}
                                            onChange={event => setRankFishNumber(event.target.value)}
                                        />
                                    </Grid>
                                )}
                                {(mode === 2 || mode === 4) && (
                                    <Grid item xs={12}>
                                        <TextField
                                            className={"d-flex mb-4"}
                                            label={"길이조건"}
                                            variant="outlined"
                                            type={"number"}
                                            value={questFishWidth}
                                            onChange={event => setQuestFishWidth(event.target.value)}
                                        />
                                    </Grid>
                                )}
                                {(mode === 3 || mode === 4) && (
                                    <Grid item xs={12}>
                                        <TextField
                                            className={"d-flex mb-4"}
                                            label={"마리조건"}
                                            variant="outlined"
                                            type={"number"}
                                            value={questFishNumber}
                                            onChange={event => setQuestFishNumber(event.target.value)}
                                        />
                                    </Grid>
                                )}
                                {mode === 5 && (
                                    <Grid item xs={12}>
                                        <TextField
                                            className={"d-flex mb-4"}
                                            label={"특정길이조건"}
                                            variant="outlined"
                                            type={"number"}
                                            value={questSpecialWidth}
                                            onChange={event => setQuestSpecialWidth(event.target.value)}
                                        />
                                    </Grid>
                                )}
                                <Grid container spacing={2}>
                                    <Grid item sm={12} md={6}>
                                        <Grid item xs={12}>
                                            <TextField
                                                className={"d-flex mb-4"}
                                                label={"총상금"}
                                                variant="outlined"
                                                type={"number"}
                                                value={totalReward}
                                                onChange={event => setTotalReward(event.target.value)}
                                            />
                                        </Grid>
                                        {(mode === 1 || mode === 5) && (
                                            <Grid item xs={12}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={"d-flex mb-3"}
                                                        label={"1위"}
                                                        variant="outlined"
                                                        type={"number"}
                                                        value={reward1}
                                                        onChange={event => setReward1(event.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={"d-flex mb-3"}
                                                        label={"2위"}
                                                        variant="outlined"
                                                        type={"number"}
                                                        value={reward2}
                                                        onChange={event => setReward2(event.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={"d-flex mb-3"}
                                                        label={"3위"}
                                                        variant="outlined"
                                                        type={"number"}
                                                        value={reward3}
                                                        onChange={event => setReward3(event.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <TextField
                                            className={"d-flex mb-5"}
                                            label={"대회약관"}
                                            multiline
                                            rows={12}
                                            rowsMax={12}
                                            variant="outlined"
                                            value={terms}
                                            onChange={event => setTerms(event.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sm={12}>
                            <div>대표이미지</div>
                            <div className="multi-image-upload">
                                {images.length > 0 && images.map((item, idx) => {
                                    return (
                                        <div
                                            key={`${idx}`}
                                            className={classes.imageItem}
                                            style={{backgroundImage: `url(${item})`}}
                                        >
                                            <div
                                                className='text-right position-absolute top-0 right-0 p-2'
                                                onClick={() => cancelAction(idx)}
                                            >
                                                <CancelIcon className='text-danger'/>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div>
                                    <StyledDropzone func={setImageUploadUrl} isMulti={true}/>
                                </div>
                            </div>
                        </Grid>
                    </CardBody>
                )}
            </Card>

            {competitionId !== 'edit' && <Card className="min-h-300px">
                <CardHeader title={'참가현황'} toolbar={attendingToolbar}/>
                <CardBody>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                            <TextField
                                id="outlined-search"
                                label={<FormattedMessage id={"GENERAL.PLACEHOLDER_SEARCH"}/>}
                                type="search"
                                className={"d-flex"}
                                margin="none"
                                variant="outlined"
                                onKeyUp={searchAction}
                                InputProps={{
                                    endAdornment: <InputAdornment position={"end"}><SearchIcon/></InputAdornment>
                                }}
                            />
                        </Grid>
                    </Grid>

                    <div className={classes.root}>
                        <Paper>
                            <div className={classes.tableWrapper}>
                                {loading ? <Spinner/> : (
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow className="bg-primary-o-50">
                                                <TableCell>순위</TableCell>
                                                <TableCell>아이디</TableCell>
                                                <TableCell>이름</TableCell>
                                                <TableCell>이메일</TableCell>
                                                <TableCell>가입날짜</TableCell>
                                                <TableCell align={"right"}>액션</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            ).map((row, idx) => {
                                                const userFishPath = '/competition/fish-list/'
                                                    + row.userId + '&' + competitionId;

                                                return (
                                                    <TableRow key={`${idx}`}>
                                                        <TableCell>{idx + 1}</TableCell>
                                                        <TableCell>{row.userId}</TableCell>
                                                        <TableCell>{row.name || '삭제됨'}</TableCell>
                                                        <TableCell>{row.email}</TableCell>
                                                        <TableCell>{row.createdDate}</TableCell>
                                                        <TableCell align={"right"}>
                                                            <div
                                                                className="btn btn-primary mr-2"
                                                                onClick={() => props.history.push(userFishPath)}
                                                            >
                                                                {'보기'}
                                                            </div>

                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 78 * emptyRows}}>
                                                    <TableCell colSpan={10}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                )}
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={totalCount || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    "aria-label": "Previous Page"
                                }}
                                nextIconButtonProps={{
                                    "aria-label": "Next Page"
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                </CardBody>
            </Card>}

        </>
    );
}
