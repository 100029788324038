/* eslint-disable no-restricted-imports */
import React, {useEffect, useState} from "react";
import {
    InputLabel,
    MenuItem,
    FormControl,
    Select
} from "@material-ui/core";
import {Card, CardHeader, CardBody} from "../../../_metronic/_partials/controls";
import {FormattedMessage} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CompetitionTabs from "./CompetitionTabs";
import {getFishById, registerCheckedFish, updateFish} from "../../../http/fishCRUD";
import {getAllFishTypes} from "../../../http/configurationCRUD";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import ImageGallery from 'react-image-gallery';


const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(2)
    },
    table: {
        minWidth: 1200
    },
    tableWrapper: {
        overflowX: "auto"
    },
    itemOverlay: {
        position: 'relative',
        width: '100%',
        height: '100%',
        // opacity: 0,
        zIndex: 300,
        backgroundColor: '#ff0000'
    },
    // galleryWrapper: {
    //     position: 'relative',
    // }
}));

const images = [
    {
        original: 'https://picsum.photos/id/1018/1000/600/',
        thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
        original: 'https://picsum.photos/id/1015/1000/600/',
        thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
        original: 'https://picsum.photos/id/1019/1000/600/',
        thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
];

export default function FishManualSetting(props) {
    const classes = useStyles();
    const fishId = window.location.pathname.split("/").pop();

    const [fishTypeList, setFishTypeList] = useState([]);
    const [user, setUser] = useState('');
    const [competition, setCompetition] = useState('');
    const [fishTypeId, setFishTypeId] = useState('');
    const [fishWidth, setFishWidth] = useState('');
    const [fishImages, setFishImages] = useState([]);
    const [rejectText, setRejectText] = useState('');
    const [status, setStatus] = useState(0);
    const [note, setNote] = useState('');
    const [showRejectPopup, setShowRejectPopup] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isLine, setIsLine] = useState(false);
    const [coordx, setCoordx] = useState(1);
    const [coordy, setCoordy] = useState(1);
    // const [screenH, setScreenH] = useState(1);
    // const [screenW, setScreenW] = useState(1);
    const [centerY, setCenterY] = useState(1);
    const [startX, setStartX] = useState(1);
    const [endX, setEndX] = useState(1);
    const [tagCenterY, setTagCenterY] = useState(1);
    const [tagStartX, setTagStartX] = useState(1);
    const [lineTop, setLineTop] = useState(0);
    const [lineLeft, setLineLeft] = useState(0);
    const [lineWidth, setLineWidth] = useState(0);
    const [tagLineTop, setTagLineTop] = useState(0);
    const [tagLineLeft, setTagLineLeft] = useState(0);
    const [tagLineWidth, setTagLineWidth] = useState(0);
    const [targetImage, setTargetImage] = useState('');
    const [imageW, setImageW] = useState(1);
    const [imageH, setImageH] = useState(1);
    const [tagEndX, setTagEndX] = useState(1);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [toastMsg, setToastMsg] = useState(false);

    useEffect(() => {
        setLoading1(true);
        setLoading2(true);
        getAllFishTypes()
            .then((res) => {
                setLoading1(false);
                setFishTypeList(res.data.result);
            })
            .catch(err => {
                console.log(err);
                setLoading1(false);
            });

        getFishById({fishId})
            .then((res) => {
                setLoading2(false);
                const data = res.data.result;
                console.log(data)
                setUser(data.user?.name);
                setCompetition(data.competition?.name);
                setFishTypeId(data.fishTypeId || '');
                setFishWidth(data.fishWidth || '');
                setRejectText(data.rejectText || '');
                setStatus(data.status);
                setFishImages(data.fishImages?.map(x => ({
                    original: x.image,
                    originalAlt: x.measureInfo,
                })));

                setTargetImage(data.fishImages?.find(x => x.imageType === 1)?.image);

            })
            .catch(err => {
                console.log(err);
                setLoading2(false);
            })

    }, []);

    const registerAction = () => {
        const data = {
            fishId,
            fishTypeId,
            fishWidth,
        };
        registerCheckedFish(data)
            .then((res) => {
                setToastMsg(true);
                props.history.push('/competition/fish-list');
            })
            .catch(err => {
                console.log(err);
            })
    }

    const rejectAction = (status) => {
        const data = {
            fishId,
            note,
            rejectText: status === 1 ? rejectText : null,
            status: status === 1 ? 2 : 1
        };

        updateFish(data)
            .then((res) => {
                setShowRejectPopup(false);
                props.history.push('/competition/fish-list');
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleSlide = (currentIndex) => {
        const currentFish = fishImages[currentIndex];

        setIsLine(!!currentFish.originalAlt);

        if (currentFish.originalAlt && isFullScreen) {
            const temp = currentFish.originalAlt.split(' | ');
            setCoordx(parseFloat(temp[0].split(', ')[0]));
            setCoordy(parseFloat(temp[0].split(', ')[1]));
            setCenterY(parseFloat(temp[1].split(', ')[0]));
            setStartX(parseFloat(temp[1].split(', ')[1]));
            setEndX(parseFloat(temp[1].split(', ')[2]));
            setTagCenterY(parseFloat(temp[2].split(', ')[0]));
            setTagStartX(parseFloat(temp[2].split(', ')[1]));
            setTagEndX(parseFloat(temp[2].split(', ')[2]));
        }

    }

    const toolbar = (
        <div className="card-toolbar">
            <button
                className="btn btn-info w-120px"
                onClick={() => props.history.push('/competition/fish-list')}
            >
                {'목록가기'}
            </button>
        </div>
    );

    return (
        <>
            {showRejectPopup && (
                <div className={"popup-container"}>
                    <div className={"popup-body warning"}>
                        <div className="popup-text font-size-h4 p-3">
                            물고기등록을 거부하시겠습니까?
                        </div>

                        <div className={"d-flex justify-content-between pt-4"}>
                            <button className={"btn btn-primary font-size-h5 w-120px"} onClick={() => rejectAction()}>
                                확인
                            </button>
                            <button className={"btn btn-danger font-size-h5 w-120px"}
                                    onClick={() => setShowRejectPopup(false)}>
                                <FormattedMessage id={"GENERAL.CANCEL"}/>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <Card className="min-h-300px">
                <CompetitionTabs match={props.match} history={props.history}/>
                <CardHeader title={"물고기판별"} toolbar={toolbar}/>
                <CardBody>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                            <ImageGallery
                                items={fishImages}
                                showPlayButton={false}
                                autoPlay={false}
                                onScreenChange={(status) => setIsFullScreen(status)}
                                onImageLoad={(event) => {
                                    if (event.target.src === targetImage) {
                                        setImageW(event.target.width);
                                        setImageH(event.target.height);
                                    }
                                    // console.log("Image URL: ", event.target.src)
                                    // console.log("Image Size: ", event.target.naturalWidth, event.target.naturalHeight);
                                    // console.log("Element Size: ", event.target.width, event.target.height);
                                }}
                                onSlide={(currentIndex) => handleSlide(currentIndex)}
                                renderCustomControls={() => {

                                    if (isFullScreen && isLine) {
                                        let screenW = window.innerWidth;
                                        let screenH = window.innerHeight - 80;
                                        let targetW, targetH, mx;

                                        if (screenW * imageH > imageW * screenH) {
                                            targetH = screenH;
                                            targetW = targetH * (imageW / imageH);
                                            mx = (screenW - targetW) / 2;
                                        } else {
                                            targetW = screenW;
                                            targetH = targetW * (imageH / imageW);
                                            mx = 0;
                                        }

                                        let scaleX = targetW / coordx;
                                        let scaleY = targetH / coordy;

                                        setLineTop(centerY * scaleY);
                                        setLineLeft(mx + startX * scaleX);
                                        setLineWidth((endX - startX) * scaleX);
                                        setTagLineTop(tagCenterY * scaleY);
                                        setTagLineLeft(mx + tagStartX * scaleX);
                                        setTagLineWidth((tagEndX - tagStartX) * scaleX);

                                        return (
                                            <div>
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: `${lineTop}px`,
                                                        left: `${lineLeft}px`,
                                                        backgroundColor: '#ff0000',
                                                        width: `${lineWidth}px`,
                                                        height: 4,
                                                        zIndex: 500,
                                                    }}>
                                                </div>
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: `${lineTop - 60}px`,
                                                        left: `${lineLeft}px`,
                                                        backgroundColor: '#ff0000',
                                                        width: 4,
                                                        height: 120,
                                                        zIndex: 500,
                                                    }}>
                                                </div>
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: `${lineTop - 60}px`,
                                                        left: `${lineLeft + lineWidth}px`,
                                                        backgroundColor: '#ff0000',
                                                        width: 4,
                                                        height: 120,
                                                        zIndex: 500,
                                                    }}>
                                                </div>

                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: `${tagLineTop}px`,
                                                        left: `${tagLineLeft}px`,
                                                        backgroundColor: '#ff0000',
                                                        width: `${tagLineWidth}px`,
                                                        height: 4,
                                                        zIndex: 500,
                                                    }}>
                                                </div>
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: `${tagLineTop - 60}px`,
                                                        left: `${tagLineLeft}px`,
                                                        backgroundColor: '#ff0000',
                                                        width: 4,
                                                        height: 120,
                                                        zIndex: 500,
                                                    }}>
                                                </div>
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: `${tagLineTop - 60}px`,
                                                        left: `${tagLineLeft + tagLineWidth}px`,
                                                        backgroundColor: '#ff0000',
                                                        width: 4,
                                                        height: 120,
                                                        zIndex: 500,
                                                    }}>
                                                </div>
                                            </div>

                                        )
                                    } else {
                                        return null
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item sm={12} md={6} className={"p-10"}>
                            <Grid container spacing={2} className="d-flex align-items-center mb-5">
                                <Grid item xs={3} sm={3}>대회명</Grid>
                                <Grid item xs={9} sm={9}>
                                    <div className="text-primary font-size-h3">{competition}</div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className="d-flex align-items-center mb-5">
                                <Grid item xs={3} sm={3}>회원이름</Grid>
                                <Grid item xs={9} sm={9}>
                                    <div className="text-primary font-size-h3">{user}</div>
                                </Grid>
                            </Grid>
                            <Grid item xl={12}>
                                <div className="text-danger text-center mb-10">
                                    물고기사진에 기초하여 물고기명과 물고기길이를 판별하세요.
                                </div>
                            </Grid>
                            <Grid container spacing={2} className="d-flex align-items-center mb-5">
                                <Grid item xs={3}>물고기명</Grid>
                                <Grid item xs={9}>
                                    <FormControl variant="outlined" className="d-flex mr-2" style={{width: '100%'}}>
                                        <InputLabel id="demo-simple-select-outlined-label">
                                            물고기명
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={fishTypeId}
                                            onChange={event => setFishTypeId(event.target.value)}
                                            label={"물고기명"}
                                        >
                                            {fishTypeList.map((item, idx) => {
                                                return (
                                                    <MenuItem value={item.id} key={`${idx}`}>{item.name}</MenuItem>
                                                )
                                            })}
                                            <MenuItem value={0}>기타</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className="d-flex align-items-center mb-5">
                                <Grid item xs={3}>물고기길이</Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        className={"d-flex mb-4"}
                                        label={"물고기길이"}
                                        variant="outlined"
                                        type={"number"}
                                        value={fishWidth}
                                        onChange={event => setFishWidth(event.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className="d-flex align-items-center mb-5">
                                <Grid item xs={3}>거부 사유</Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        className={"d-flex mb-4"}
                                        label={"거부 사유"}
                                        variant="outlined"
                                        type={"text"}
                                        value={rejectText}
                                        onChange={event => setRejectText(event.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <div className="d-flex justify-content-end">
                                <button
                                    className="btn btn-danger w-120px mr-4"
                                    onClick={() => rejectAction(status)}
                                >
                                    {status === 1 ? '거부' : '거부취소'}
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </CardBody>
            </Card>
        </>
    );
}
